import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectSocialMediaData } from '../../../features/static';
import useHeaderTheme from '../../../utils/customHooks/useHeaderTheme';
import { getSiteURl } from '../../../utils/senders/SendSplunkReport';
import HomePageSchemaStructure from './HomePageSchemaStructure';

const HomePageSchema: FC = () => {
  const { brandLogo } = useHeaderTheme();
  const socialMediaData = useSelector(selectSocialMediaData);
  const socialMediaUrls = socialMediaData?.accounts.map(account => account.url);
  const siteUrl = getSiteURl(process.env.NEXT_PUBLIC_MIDDLEWARE_SITEID as string);

  const organizationData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: brandLogo?.alt,
    url: siteUrl,
    logo: brandLogo?.desktop?.src,
    sameAs: socialMediaUrls
  };

  const websiteData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: siteUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: `${siteUrl}/enrichmentdata/search/index?q={search_term_string}`
      },
      'query-input': 'required name=search_term_string'
    }
  };

  const componentProps = {
    organizationData: JSON.stringify(organizationData),
    websiteData: JSON.stringify(websiteData),
  };
  
  return <HomePageSchemaStructure {...componentProps} />;
};

export default HomePageSchema;
