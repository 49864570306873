import { LDFlagSet } from '@launchdarkly/node-server-sdk';

type ExtendedWindow = Window &
  typeof globalThis & {
    newrelic: {
      addPageAction: (id: string, opts?: { [key: string]: string }) => void;
    };
  };

const useNewRelicPageAction = (toggles: LDFlagSet): {
  handlePlpClick: () => void;
  handlePageAction: (
    id: string,
    opts?:
      | {
        [key: string]: string;
      }
      | undefined,
  ) => void;
  handleHomepageClick: () => void;
} => {
  const shouldFire: boolean = toggles && Object.keys(toggles).length > 0 && toggles.featureNewRelicOnClickListener;

  /**
   * send page action to new relic
   * @param id
   * @param opts
   */
  const handlePageAction = (id: string, opts?: { [key: string]: string }) => {
    if (shouldFire) {
      if (typeof window !== 'undefined') {
        (window as ExtendedWindow).newrelic.addPageAction(id, { ...opts });
      }
    }
  };

  /**
   * find all links to plp and attach event listeners on those links
   * when user clicks on those links send action to new relic
   */
  const handleHomepageClick = () => {
    if (shouldFire) {
      if (typeof document !== 'undefined') {
        const homepageLinks = document.getElementsByTagName('a');
        const regexp = new RegExp(
          /\b(?:story|stories|read|sign|facebook|instagram|twitter|more)\b/g,
        );
        const hrefRegexp = new RegExp(/\b(?:help|play|apps)\b/g);
        Array.from(homepageLinks)
          .filter((link) => !regexp.test(link.textContent?.toLowerCase() as string))
          .filter((link) => !hrefRegexp.test(link.href))
          .forEach((link) => {
            link.addEventListener('click', () => {
              handlePageAction('plp', { pageAction: 'clickedPlp' });
            });
          });
      }
    }
  };

  /**
   * find all links to pdp and attach event listeners on those links
   * when user clicks on those links send action to new relic
   */
  const handlePlpClick = () => {
    if (shouldFire) {
      if (typeof document !== 'undefined') {
        const productListItems = document.getElementsByClassName('product-item');
        const products = Array.from(productListItems);
        products.forEach((product) => {
          const linkItems = product.getElementsByTagName('a');
          const linkArray = Array.from(linkItems);
          linkArray.forEach((link) =>
            link.addEventListener('click', () => {
              handlePageAction('pdp', { pageAction: 'clickedPdp' });
            }),
          );
        });
      }
    }
  };

  return { handlePlpClick, handlePageAction, handleHomepageClick };
};

export default useNewRelicPageAction;
