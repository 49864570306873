import React, { FC } from 'react';
import Head from 'next/head';

interface HomePageSchemaStructureProps {
  organizationData: string;
  websiteData: string;
}

const HomePageSchemaStructure: FC<HomePageSchemaStructureProps> = ({ organizationData, websiteData }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        id="organizationSchema"
        dangerouslySetInnerHTML={{ __html: organizationData }}
      />
      <script
        type="application/ld+json"
        id="websiteSchema"
        dangerouslySetInnerHTML={{ __html: websiteData }}
      />
    </Head>
  );
};

export default HomePageSchemaStructure;
