import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ErrorPolicy } from '@apollo/client';
import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { setMetadata } from '../shared/features/metadata';
/* Components */
import {
  selectToggles,
  setHeaderColourTheme,
  setSiteData,
  setToggleData,
} from '../shared/features/static';
import { pageBuilderQuery } from '../shared/graphQl/queries/pageBuilder';
import HomePageContent from '../shared/pageComponents/HomePageContent';
import { GlobalEOverride } from '../shared/styles/overrides';
import { StoreState } from '../shared/types/redux-types';
import useNewRelicPageAction from '../shared/utils/customHooks/useNewRelicPageAction';
import getPageDataSSR from '../shared/utils/getters/getPageDataSSR';
import createSSRApolloClient from '../shared/utils/helpers/createSSRApolloClient';
import triggerGlobalActions from '../shared/utils/helpers/triggerGlobalActions';

interface HomePageProps {
  initialReduxState?: StoreState;
  toggles?: LDFlagSet;
}

const pageName = 'HomePage';
const config = {
  variables: { id: '/' },
  errorPolicy: 'ignore' as ErrorPolicy,
};

const HomePage: FC<HomePageProps> = () => {
  const hidePromo = process.env.NEXT_PUBLIC_LD_SITE === 'kgglobal' || process.env.NEXT_PUBLIC_LD_SITE === 'kgmx' ;
  const reduxToggles = useSelector(selectToggles);
  const { handleHomepageClick, handlePageAction } = useNewRelicPageAction(reduxToggles);

  useEffect(() => {
    handleHomepageClick();
  }, [handleHomepageClick]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handlePageAction('home', { pageAction: 'clickedHome' });
    }, 3000);
    return () => clearTimeout(timer);
  }, [handlePageAction]);

  return (
    <ParallaxProvider>
      {hidePromo && <GlobalEOverride />}
      <HomePageContent />
    </ParallaxProvider>
  );
};

export const getServerSideProps = async (
  ctx: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<HomePageProps>> => {

  const referrer = ctx?.req?.headers?.referer || null;
  const { apolloClient, toggles } = await createSSRApolloClient(ctx);

  const getContentBlockMediaBasic = apolloClient.query({
    query: pageBuilderQuery.GET_CMS_PAGE_BUILDER_DATA,
    variables: {
      id: config.variables.id,
    },
  });
  const reduxDataQueries = [getContentBlockMediaBasic];

  const { hasErrors, notFound, reduxStore, reduxData } = await getPageDataSSR({
    reduxDataQueries,
    config,
    context: ctx,
    apolloClient,
    checkData: 'CMSPageBuilder',
    toggles,
  });

  if (notFound || hasErrors) {
    return {
      notFound: true,
    };
  }

  const { dispatch } = reduxStore;

  const mappedReponses = reduxData?.reduce((previous, current) => {
    if (current.CMSPageBuilder) {
      return { ...previous, CMSPageBuilder: current.CMSPageBuilder };
    }
    return previous;
  }, {});

  const allPageData = mappedReponses?.CMSPageBuilder;

  const finalPageData = { pageName, referrer, ...allPageData };

  if (finalPageData) {
    dispatch(setHeaderColourTheme(allPageData?.headerColourTheme));
    dispatch(setSiteData(finalPageData));
  }
  if (toggles) {
    dispatch(setToggleData(toggles));
  }

  if (allPageData?.metadata) {
    dispatch(setMetadata(allPageData?.metadata));
  }

  triggerGlobalActions(ctx, 'homepage', toggles, dispatch);

  const initialReduxState = reduxStore.getState();

  return {
    props: {
      initialReduxState,
      toggles,
    },
  };
};

export default HomePage;
