import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectBackground, selectLogo, selectPageBlocks } from '../../features/static';
import HomePageContentStructure from './HomePageContentStructure';

const HomePageContent: FC = () => {
  const logo = useSelector(selectLogo);
  const background = useSelector(selectBackground);
  const blocks = useSelector(selectPageBlocks);

  const componentProps = {
    blocks,
    logo,
    background,
  }
  return <HomePageContentStructure {...componentProps} />;
};

export default HomePageContent;
