import React, { FC } from 'react';

import BlocksArray from '../../components/BlocksArray';
import HomePageSchema from '../../components/Schemas/HomePage';
import { CMSBlocks } from '../../types/custom-types';
import { ContentBlock, ImageV2, Maybe } from '../../types/middleware-types';

interface HeaderProps {
  blocks?: Maybe<Array<CMSBlocks>> | Maybe<Array<ContentBlock>>;
  background?: Maybe<ImageV2>;
  logo?: Maybe<ImageV2>;
}

const HomePageContentStructure: FC<HeaderProps> = ({
  blocks,
  logo,
  background,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const componentProps = {
    blocks,
    logo,
    background,
  }
  return (
    <>
      <BlocksArray {...componentProps} />
      <HomePageSchema />
    </>
  )
};

export default HomePageContentStructure;
